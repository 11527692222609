
import { defineComponent } from 'vue'
import HeaderTit from '@/components/HeaderTit.vue'
import { useRouter } from 'vue-router'
import AxiosAdapter from '../request/AxiosAdapter'
import { removeStorage } from '../commons/SessionStorageUtils'

export default defineComponent({
  name: 'PersonalCenter',
  components: { HeaderTit },
  setup() {
    const router = useRouter()

    const loginOut = async () => {
      await AxiosAdapter.post({url: '/webElectronic/loginOut'})
      removeStorage('Token')
      removeStorage('Key')
      router.push({
        path: '/'
      })
    }
    const gotoPage = () => {
      router.back()
    }
    const gotoConfirm = () => {
      router.push({
        path: '/personal-confirm-address'
      })
    }
    const gotoCertification = () => {
      router.push({
        path: '/name-certification'
      })
    }

    
    return { loginOut, gotoPage, gotoConfirm, gotoCertification }
  }
})
